import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import FlexibleContent from "../components/FlexibleContent"
import Spacer from "../components/Spacer"
import Seo from "../components/Seo"
import FeaturedProjects from "../components/FeaturedProjects"
import { breakpoints } from "../assets/globalStyles"

export const caseStudies = graphql`
  query($id: String!, $categoryNames: [String]) {
    wpCaseStudy(id: { eq: $id }) {
      title
      categories {
        nodes {
          name
        }
      }
      caseStudy {
        summary
        customerType
        clientIndustry
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
            publicURL
          }
          mimeType
        }
      }
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      flexibleGeneralContentFields {
        ...FlexibleContentCaseStudy
      }
    }
    allWpCaseStudy(
      limit: 2
      filter: {
        categories: { nodes: { elemMatch: { name: { in: $categoryNames } } } }
        id: { ne: $id }
      }
    ) {
      nodes {
        id
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
              publicURL
            }
            mimeType
          }
        }
        title
        slug
        caseStudy {
          clientIndustry
          customerType
          summary
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`

const StyledHeader = styled.div`
  background-color: #d42b69;
  box-shadow: inset 0 -24px 0 #eef1f4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    box-shadow: inset 0 -48px 0 #eef1f4;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    box-shadow: inset 0 -56px 0 #eef1f4;
  }
  @media screen and (min-width: ${breakpoints.lg}px) {
    box-shadow: inset 0 -88px 0 #eef1f4;
  }
  .image-wrapper {
    height: 170px;
    @media screen and (min-width: ${breakpoints.sm}px) {
      height: 250px;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      height: 300px;
    }
    @media screen and (min-width: ${breakpoints.lg}px) {
      height: 450px;
    }
    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`

const StyledCaseStudyDetails = styled.div`
  background-color: #eef1f4;

  h1 {
    color: #1c1c26;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 34px;
      line-height: 52px;
      text-align: center;
    }
  }

  .case-study-info {
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: ${breakpoints.lg}px) {
      flex-direction: row;
    }
    p {
      color: #1c1c26;
      font-family: Roboto;
      font-size: 18px;
      line-height: 28px;
      @media screen and (min-width: ${breakpoints.md}px) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    h4 {
      color: #66768e;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      min-width: 275px;
    }

    h5 {
      color: #1c1c26;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }
  }
`

export default function CaseStudyTemplate({
  data: {
    allWpCaseStudy: { nodes: relatedCaseStudies },
    wpCaseStudy: {
      caseStudy: { clientIndustry, customerType, summary },
      categories: { nodes: categories },
      featuredImage,
      flexibleGeneralContentFields: { content },
      seo,
      title,
    },
  },
}) {
  // format featured image based on the file type

  const imageType = featuredImage.node.mimeType
  const imageUrl = featuredImage.node.localFile?.publicURL
  const gatsbyImageData =
    featuredImage.node.localFile?.childImageSharp?.gatsbyImageData

  // get all categories
  const category = categories.map(({ name }) => name).join(", ")

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <StyledHeader>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <Spacer size={8} when={{ sm: 16, md: 24, lg: 32 }} />
              <div className="image-wrapper">
                {imageType === "image/svg+xml" ? (
                  <img src={imageUrl} alt={title} />
                ) : (
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={title}
                    objectFit="contain"
                    style={{ maxHeight: "100%", height: "100%" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledHeader>
      <StyledCaseStudyDetails>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Spacer size={32} when={{ md: 56, lg: 80 }} />
              <h1>{title}</h1>
              <Spacer size={32} when={{ md: 56, lg: 80 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="case-study-info">
                <div>
                  <h4>Project Type</h4>
                  <h5>{category}</h5>
                  <Spacer size={24} />
                  <h4>Customer Type:</h4>
                  <h5>{customerType}</h5>
                  <Spacer size={24} />
                  <h4>Industry:</h4>
                  <h5>{clientIndustry}</h5>
                </div>
                <Spacer size={24} when={{ lg: 32 }} />
                <div
                  dangerouslySetInnerHTML={{ __html: `<p>${summary}</p>` }}
                />
              </div>
              <Spacer size={32} when={{ md: 56, lg: 80 }} />
            </div>
          </div>
        </div>
      </StyledCaseStudyDetails>
      <Spacer size={32} when={{ md: 48, lg: 60 }} />
      <FlexibleContent content={content} />
      <Spacer size={32} when={{ md: 48 }} />
      {!!relatedCaseStudies.length && (
        <FeaturedProjects
          title="Related Projects"
          caseStudies={relatedCaseStudies}
          showLinkToAllCaseStudies={false}
        />
      )}
      <Spacer size={32} when={{ md: 48, lg: 60 }} />
    </>
  )
}
